import styled from 'styled-components'
import { Box } from '@rebass/grid'

const Container = styled(Box)`
  width: 100%;
  max-width: 1110px;
`
Container.defaultProps = {
  //mx: 'auto',
}

export default Container
