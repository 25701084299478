import React from 'react'
import Button from '@material-ui/core/Button'
import styled from 'styled-components'

export const Btn = ({ children, className }) => {
  return <button className={className}>{children}</button>
}

export const StyledBtn = styled(Btn)`
  border: 2px solid ${props => props.color};
  padding: 4.5px 25px;
  color: ${props => props.color};
  background: transparent;
  font-weight: 300;
  font-size: 13px;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 6px;
  transition: 0.3s;
  &:hover {
    background-color: ${props => props.color};
    color: black;
  }
`

StyledBtn.defaultProps = {
  color: 'white',
}

export const OutlineButton = styled(
  ({ color, borderColor, hoverColor, hoverBackground, ...otherProps }) => (
    <Button {...otherProps} variant="outlined" />
  )
)`
  color: ${props => props.color} !important;
  font-family: Heebo !important;
  font-style: normal !important;
  border-radius: 6px;
  min-height: 44px;
  font-weight: 500 !important;
  font-size: 13px !important;
  padding: 3px 25px !important;
  border: 1px solid
    ${props => (props.borderColor ? props.borderColor : props.color)} !important;
  &:hover {
    background-color: ${props =>
      props.hoverBackground ? props.hoverBackground : props.color} !important;
    border: 1px solid
      ${props => (props.borderColor ? props.borderColor : props.color)} !important;
    color: ${props =>
      props.hoverColor ? props.hoverColor : props.color} !important;
  }
`
