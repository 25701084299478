import React from 'react'
import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import Container from './Container'

const Section = styled(Flex)`
  position: relative;
`

const SectionBackground = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transform: skewY(-2deg);
  transform-origin: top left;
`

Section.defaultProps = {
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'stretch',
}

export default props => (
  <Section mt={-1}>
    <SectionBackground>{props.background}</SectionBackground>
    <Container {...props}>{props.children}</Container>
  </Section>
)
